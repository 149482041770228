import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function Homemaker({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="life-skill.jpg" />
        </figure>
        <p>
          Homemaker services provide assistance to persons with the inability to
          perform one or more of the following activities of daily living:
        </p>
        <ul className="list-unstyled">
          <li>
            <i className="bi bi-check2-square"></i> Housekeeping
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Including but not limited to
            light cleaning and repairs
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Meal preparation
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Assisting during shopping
            and groceries
          </li>
        </ul>
      </div>
    </div>
  );
}
