import React from "react";
import { TabProps } from "@/types/Services";
import PersonalCare from "@/components/Services/Tab/items/PersonalCare";
import PersonalSupport from "@/components/Services/Tab/items/PersonalSupport";
import Homemaker from "@/components/Services/Tab/items/Homemaker";
import Respite from "@/components/Services/Tab/items/Respite";
import LifeSkill from "@/components/Services/Tab/items/LifeSkill";
import PrivateDutyNursing from "@/components/Services/Tab/items/PrivateDutyNursing";
import ResidentialNursing from "@/components/Services/Tab/items/ResidentialNursing";
import SkilledNursing from "@/components/Services/Tab/items/SkilledNursing";
const Tab = ({ tabActive }: TabProps) => {
  return (
    <div className="tab-content">
      <PersonalCare active={tabActive === "personalCare"} />
      <PersonalSupport active={tabActive === "personalSupport"} />
      <Homemaker active={tabActive === "homemaker"} />
      <Respite active={tabActive === "respite"} />
      <LifeSkill active={tabActive === "lifeSkill"} />
      <PrivateDutyNursing active={tabActive === "privateDutyNursing"} />
      <ResidentialNursing active={tabActive === "residentialNursing"} />
      <SkilledNursing active={tabActive === "skilledNursing"} />
    </div>
  );
};

export default Tab;
