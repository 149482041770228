import React from "react";
import Image from "@/components/Image";

const BannerTop = () => {
  return (
    <div className="banner-part d-grid align-content-center">
      <div className="sub-banner">
        <div className="container">
          <h1>Service</h1>
          {/* <ul className="d-flex justify-content-center list-unstyled">
            <li>Home</li>
            <li>/</li>
            <li>
              <a href="services.html"> Service </a>
            </li>
          </ul> */}
        </div>
      </div>
      <Image name="sub-banner.jpg" />
    </div>
  );
};

export default BannerTop;
