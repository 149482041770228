import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function PrivateDutyNursing({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="private-nursing.jpg" />
        </figure>
        <p>
          Private duty nursing (RN/LPN) is skilled nursing care provided 24hr 1 to 1 continuous care administrating medications
          and supporting needs and activities of daily living (ADLs).
        </p>
      </div>
    </div>
  );
}
