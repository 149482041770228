import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function SkilledNursing({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="skilled-nursing.jpg" />
        </figure>
        <p>
          Skilled nursing care refers to a patient’s need for care or treatment
          that can only be performed by licensed nurses. Providing 24hr 1 to 1
          continuous care administrating medications and supporting needs and
          activities of daily living (ADLs).
        </p>
      </div>
    </div>
  );
}
