import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function PersonalSupport({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="support.png" />
        </figure>
        <p>
          Personal support services include assistance for recipients over 21 with
          activities of daily living to accomplish tasks that they would
          normally be able to do for themselves if they did not have a medical
          conditional or disability.
        </p>
        <p>Providing assistance and training on activities such as:</p>
        <ul className="list-unstyled">
          <li>
            <i className="bi bi-check2-square"></i> Eating
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Bathing
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Dressing
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Personal hygiene
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Preparation of meals
          </li>
        </ul>
      </div>
    </div>
  );
}
