import React, { useEffect } from "react";
import Image from "@/components/Image";
import Tab from "@/components/Services/Tab";

const MainContent = ({ showTab }: any) => {
  const [activeTab, setActiveTab] = React.useState("personalCare");

  useEffect(() => {
    if (showTab) {
      setActiveTab(showTab);
    }
  }, [showTab]);

  return (
    <>
      <div className="clearfix"></div>
      <div
        className="total-find-page comon-sub-div"
        style={{ paddingBottom: "3rem" }}
      >
        <div className="container">
          <h1 className="text-center mb-4 text-green">We Provide High Quality Services</h1>
          <div className="services-part-div">
            <div className="d-lg-flex align-items-start d-md-flex">
              <div className="nav flex-column nav-pills me-3">
                <button
                  className={`nav-link ${
                    activeTab === "personalCare" && "active"
                  }`}
                  type="button"
                  onClick={() => setActiveTab("personalCare")}
                >
                  <figure>
                    <Image
                      name="personal-care-icon.png"
                      classes="card-img-top"
                    />
                  </figure>
                  Personal Care
                </button>
                <button
                  className={`nav-link ${
                    activeTab === "personalSupport" && "active"
                  }`}
                  type="button"
                  onClick={() => setActiveTab("personalSupport")}
                >
                  <figure>
                    <Image
                      name="personal-support-icon.png"
                      classes="card-img-top"
                    />
                  </figure>
                  Personal Support
                </button>
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "lifeSkill" && "active"
                  }`}
                  onClick={() => setActiveTab("lifeSkill")}
                >
                  <figure>
                    <Image name="wheelchair-64.png" classes="card-img-top" />
                  </figure>
                  Life Skill (Companion)
                </button>
                <button
                  className={`nav-link ${activeTab === "respite" && "active"}`}
                  type="button"
                  onClick={() => setActiveTab("respite")}
                >
                  <figure>
                    <Image name="respite-icon.png" classes="card-img-top" />
                  </figure>
                  Respite
                </button>
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "homemaker" && "active"
                  }`}
                  onClick={() => setActiveTab("homemaker")}
                >
                  <figure>
                    <Image name="homemaker-icon.png" classes="card-img-top" />
                  </figure>
                  Homemaker
                </button>
              
                
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "privateDutyNursing" && "active"
                  }`}
                  onClick={() => setActiveTab("privateDutyNursing")}
                >
                  <figure>
                    <Image
                      name="private-nursing-icon.png"
                      classes="card-img-top"
                    />
                  </figure>
                  Private Duty Nursing
                </button>
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "residentialNursing" && "active"
                  }`}
                  onClick={() => setActiveTab("residentialNursing")}
                >
                  <figure>
                    <Image
                      name="residential-nursing-icon.png"
                      classes="card-img-top"
                    />
                  </figure>
                  Residential Nursing
                </button>
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "skilledNursing" && "active"
                  }`}
                  onClick={() => setActiveTab("skilledNursing")}
                >
                  <figure>
                    <Image
                      name="skilled-nursing-icon.png"
                      classes="card-img-top"
                    />
                  </figure>
                  Skilled Nursing
                </button>
              </div>
              <Tab tabActive={activeTab} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
