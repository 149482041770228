import React from "react";
import BannerTop from "@/components/Services/BannerTop";
import MainContent from "./MainContent";
import Subscription from "@/components/Home/Subscription";

const Services = ({ showTab } : any) => {
  return <>
  <BannerTop/>
  <MainContent showTab={showTab}/>
  <Subscription/>
  </>;
};

export default Services;
