import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function Respite({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="respite.png" />
        </figure>
        <p>
          Respite care is a service that provides supportive care and
          supervision to recipients under the age of 21 when the primary
          caregiver is unable to perform the duties of a caregiver. This service
          is used due to a brief planned or emergency absence, or when the
          primary caregiver is available, but temporarily physically unable to
          care for or supervise the recipient for a brief period of time.
        </p>
        {/* <p>The staff can help your loved one: </p>
        <ul className="list-unstyled">
          <li>
            <i className="bi bi-check2-square"></i> Bathe
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Dress
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Eat or drink
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Take medications on schedule
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Exercise
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Get in and out of bed
          </li>
        </ul> */}
      </div>
    </div>
  );
}
