import React, { useEffect } from "react";
import Layout from "@/components/layout";
import Header from "@/components/Header";
import Services from "@/components/Services";
import Footer from "@/components/Footer";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";

const Serv = ({ location }: any) => {
  const { state = {} } = location;
  const { showActiveTab } = state;
  const { host } = useLocation();
  const canonical = `${host}/services`;
  return (
    <Layout>
      <SEO title="Services" canonical={canonical}/>
      <Header />
      <Services showTab={showActiveTab}/>
      <Footer />
    </Layout>
  );
};

export default Serv;
