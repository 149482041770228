import React from "react";
import Image from "@/components/Image";
import { TabItemProps } from "@/types/Services";

export default function LifeSkill({ active }: TabItemProps) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <div className="comon-tabs-sec-d1">
        <figure>
          <Image name="lifesk.png" />
        </figure>
        <p>
          Life Skill (Companion) provides essential assistance to the elderly
          and disabled adults at their home or accompany them on errands or
          activities outside the house.
        </p>
        <p>Services include:</p>
        <ul className="list-unstyled">
          <li>
            <i className="bi bi-check2-square"></i> Personal Care
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Light Housekeeping
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Medication Reminders
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Errands
          </li>
          <li>
            <i className="bi bi-check2-square"></i> Grocery Shopping
          </li>
        </ul>
      </div>
    </div>
  );
}
